import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-1/pic8.jpeg",
];

export default function GreenDayCelebrations() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Green Day Celebration</h1>

        <p>
          <strong>
            "Green is a mix of blue and yellow which symbolizes glory and
            energy."
          </strong>
          <br />
          <br />
          Green Day was celebrated with great zeal and enthusiasm by the Pre
          Primary learners of Chinmaya Vidyalaya, New Delhi on 6th July, 2023.
          It aimed at helping the little learners recognise the colour and
          relate it to things around them. The children and teachers, dressed in
          green coloured attire, enjoyed talking about Nature and the green
          objects around them. This was followed by a non fire cooking activity
          of a healthy green salad to develop their fine motor skills. It was a
          wonderful learning experience for them. They were happy and enjoyed
          the activity to the fullest.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
